import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import firebase from "../firebase";
import AppContext from "../provider/AppContext";

export default function WeiterB() {
  const navigate = useNavigate();
  const { inhalt, proindex } = useContext(AppContext);
  const ref = firebase.firestore();

  const [vali, setVali] = useState("");

  function weiterC() {
    if (inhalt[proindex].hobbies.length === 6) {
      ref
        .collection(inhalt[proindex].code)
        .doc("profil")
        .update({ weiter: true })
        .then(navigate("/chat"))
        .catch((err) => {
          console.error(err);
        });
    } else {
      setVali("Bitte wählen Sie 5 Hobbies für Ihr Datingprofil aus");
    }
  }
  return (
    <div>
      <button onClick={weiterC}>Weiter</button>
      <p className="texteins">{vali}</p>
    </div>
  );
}
