import React, { Fragment, useState, useContext, useEffect } from "react";

import firebase from "../firebase";
import AppContext from "../provider/AppContext";
import CFenster from "./CFenster";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function PersonF() {
  const { cname, pathy, chathlt, setChathlt, inhalt, setLaden, proindex } =
    useContext(AppContext);
  const ref = firebase.firestore().collection(inhalt[proindex].code);
  const [name, setName] = useState("");

  const [nachcount, setNachcount] = useState(0);

  const janan = [
    [
      "Hallo, du bist ja originell ^^ ...ich mag Dein Profil. Wir haben viele gemeinsame Interessen",
    ],
    ["Hä? ... das verstehe ich nicht.."],
    ["Verreist Du gerne?"],
    [
      "Ich mag Kurztrips. Meine beste Freundin war vor einiger Zeit in Aachen. Da möchte ich auch mal hin",
    ],
    [
      "Ich muss gehen. Lass uns auf jeden Fall wann anders mal weiter schreiben!",
    ],
  ];

  const janhob = [
    "Hi, das ist nett. Ich sehe gerade, dass wir von unseren Hobbies her gar nicht gut zusammen passen. Ich habe deshalb kein Interesse. Alles Gutes Dir. :)",
  ];

  const janalt = [
    "Hey...ich empfinde unseren Altersunterschied als etwas zu groß. Ich habe deshalb kein Interesse. Alles Gute Dir. :)",
  ];

  const nachrichten = [
    [
      "Hi, mir ist gerade ein bisschen langweilig. Wie geht's Dir?",
      "Hey, das ist nett. Wie gehts?",
      "yo yo yo...was geht ab?",
      "hey :)",
      "Moin Moin, du hast ja unterschiedliche Hobbys. Wow.",
      "Hallihallo",
    ],
    [
      "Ich finde Dein Profil mega interessant",
      "Wir sind von den Interessen her schon ziemlich verschieden. Aber Gegensätze ziehen sich an",
      "Du wirkst echt spannend...",
      "Faszinierend.",
    ],
    [
      "... ich bin mir nicht sicher, ob ich das richtig verstehe",
      "Ich bin mir nicht sicher, wie ich das interpretieren soll.",
      "Kannst Du das präzisieren?",
      "Ich weiß nicht so recht...",
    ],
    [
      "Kletterst Du lieber in der Natur oder an künstlichen Kletterwänden?",
      "Bist Du gerne viel an der frischen Luft?",
      "Interessierst Du Dich auch für Geschichte?",
      "Sprichst Du noch andere Sprachen außer Deutsch? Je parle francais...",
    ],
    [
      "Das finde ich ziemlich interessant",
      "Das ist echt cool",
      "Ich finde das ziemlich schräg...aber sympatisch.",
      "Haha... da muss ich jetzt lachen.",
    ],
    [
      "Ich muss leider los. Wir sollten uns aber unbedingt später weiter unterhalten!",
    ],
  ];

  function probee() {
    return Math.random() * (5 - 0) + 0;
  }

  useEffect(() => {
    if (pathy === "ping") {
    } else {
      const unsubscribe = ref.doc(cname).onSnapshot(function (doc) {
        setChathlt(doc.data());
        setLaden(false);
      });

      return () => {
        unsubscribe();
      };
    }
  }, []);

  let chathelper = [];

  let ranz = 0;
  function sendb(txt) {
    ranz = Math.floor(Math.random() * (5 - 0) + 0);
    if (chathlt.chat[0] === "") {
      chathelper.push(txt);
      ref
        .doc(cname)
        .update({ chat: chathelper })
        .catch((err) => {
          console.error(err);
        });
      setName("");
      setTimeout(() => myFunction(txt), 3000);
    } else {
      chathelper = chathlt.chat;
      chathelper.push(txt);
      ref
        .doc(cname)
        .update({ chat: chathelper })
        .catch((err) => {
          console.error(err);
        });
      setName("");
      setTimeout(() => myFunction(txt), 3000);
    }
  }

  function myFunction(gegeben) {
    console.log(nachcount, "Nachcount");
    console.log(janan.length, "janan.length");
    console.log(chathlt.chat[0], "chathlt.chat[0]");
    if (chathlt.chat[0] === "") {
      if (
        cname === "Jana" &&
        inhalt[proindex].hobbies.includes("Tennis") &&
        inhalt[proindex].hobbies.includes("Klettern") &&
        inhalt[proindex].hobbies.includes("DeutschRap") &&
        inhalt[proindex].hobbies.includes("Architektur") &&
        inhalt[proindex].hobbies.includes("Gaming/Computer Spiele") &&
        nachcount < janan.length &&
        inhalt[proindex].age < 31 &&
        inhalt[proindex].age > 27
      ) {
        chathelper = [gegeben];

        chathelper.push(
          janan[nachcount][
            Math.floor(Math.random() * (janan[nachcount].length - 0) + 0)
          ]
        );

        ref
          .doc(cname)
          .update({ chat: chathelper })
          .then((chathelper = []))
          .catch((err) => {
            console.error(err);
          });

        if (nachcount < janan.length) {
          setNachcount(nachcount + 1);
        }
      } else if (
        cname === "Jana" &&
        (inhalt[proindex].age > 30 || inhalt[proindex].age < 28)
      ) {
        chathelper = [gegeben];

        chathelper.push(janalt[0]);

        ref
          .doc(cname)
          .update({ chat: chathelper })
          .then((chathelper = []))
          .catch((err) => {
            console.error(err);
          });
      } else if (
        cname === "Jana" &&
        (!inhalt[proindex].hobbies.includes("Tennis") ||
          !inhalt[proindex].hobbies.includes("Klettern") ||
          !inhalt[proindex].hobbies.includes("DeutschRap") ||
          !inhalt[proindex].hobbies.includes("Architektur") ||
          !inhalt[proindex].hobbies.includes("Gaming/Computer Spiele"))
      ) {
        chathelper = [gegeben];

        chathelper.push(janhob[0]);

        ref
          .doc(cname)
          .update({ chat: chathelper })
          .then((chathelper = []))
          .catch((err) => {
            console.error(err);
          });
      } else if (cname !== "Jana" && nachcount < nachrichten.length) {
        chathelper = [gegeben];
        console.log(
          "Was ist nachricht 1 ",
          nachrichten[nachcount][
            Math.floor(Math.random() * (nachrichten[nachcount].length - 0) + 0)
          ]
        );
        chathelper.push(
          nachrichten[nachcount][
            Math.floor(Math.random() * (nachrichten[nachcount].length - 0) + 0)
          ]
        );

        ref
          .doc(cname)
          .update({ chat: chathelper })
          .then((chathelper = []))
          .catch((err) => {
            console.error(err);
          });
        if (nachcount < nachrichten.length) {
          setNachcount(nachcount + 1);
        }
      } else {
        chathelper = chathlt.chat;
        chathelper.push(`${cname} hat den Chat verlassen`);

        ref
          .doc(cname)
          .update({ chat: chathelper })
          .then((chathelper = []))
          .catch((err) => {
            console.error(err);
          });
      }
    } else {
      if (
        cname === "Jana" &&
        inhalt[proindex].hobbies.includes("Tennis") &&
        inhalt[proindex].hobbies.includes("Klettern") &&
        inhalt[proindex].hobbies.includes("DeutschRap") &&
        inhalt[proindex].hobbies.includes("Architektur") &&
        inhalt[proindex].hobbies.includes("Gaming/Computer Spiele") &&
        nachcount < janan.length &&
        inhalt[proindex].age < 31 &&
        inhalt[proindex].age > 27
      ) {
        chathelper = chathlt.chat;

        chathelper.push(
          janan[nachcount][
            Math.floor(Math.random() * (janan[nachcount].length - 0) + 0)
          ]
        );

        ref
          .doc(cname)
          .update({ chat: chathelper })
          .then((chathelper = []))
          .catch((err) => {
            console.error(err);
          });

        if (nachcount < janan.length) {
          setNachcount(nachcount + 1);
        }
      } else if (
        cname === "Jana" &&
        (inhalt[proindex].age > 30 || inhalt[proindex].age < 28)
      ) {
        chathelper = chathlt.chat;
        chathelper.push(`${cname} hat den Chat verlassen`);

        ref
          .doc(cname)
          .update({ chat: chathelper })
          .then((chathelper = []))
          .catch((err) => {
            console.error(err);
          });
      } else if (
        cname === "Jana" &&
        (!inhalt[proindex].hobbies.includes("Tennis") ||
          !inhalt[proindex].hobbies.includes("Klettern") ||
          !inhalt[proindex].hobbies.includes("DeutschRap") ||
          !inhalt[proindex].hobbies.includes("Architektur") ||
          !inhalt[proindex].hobbies.includes("Gaming/Computer Spiele"))
      ) {
        chathelper = chathlt.chat;
        chathelper.push(`${cname} hat den Chat verlassen`);

        ref
          .doc(cname)
          .update({ chat: chathelper })
          .then((chathelper = []))
          .catch((err) => {
            console.error(err);
          });
      } else if (cname !== "Jana" && nachcount < nachrichten.length) {
        chathelper = chathlt.chat;
        console.log(
          "Was ist nachricht 2 ",
          nachrichten[nachcount][
            Math.floor(Math.random() * (nachrichten[nachcount].length - 0) + 0)
          ]
        );
        chathelper.push(
          nachrichten[nachcount][
            Math.floor(Math.random() * (nachrichten[nachcount].length - 0) + 0)
          ]
        );

        ref
          .doc(cname)
          .update({ chat: chathelper })
          .then((chathelper = []))
          .catch((err) => {
            console.error(err);
          });
        if (nachcount < nachrichten.length) {
          setNachcount(nachcount + 1);
        }
      } else {
        chathelper = chathlt.chat;
        chathelper.push(`${cname} hat den Chat verlassen`);

        ref
          .doc(cname)
          .update({ chat: chathelper })
          .then((chathelper = []))
          .catch((err) => {
            console.error(err);
          });
      }
    }
  }

  function backs() {
    ref
      .doc("profil")
      .update({ active: "none" })
      .then((chathelper = []))
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <Fragment>
      <div className="centering textcenter">
        <div className="margin-top margin-rund">
          <div className="pointer">
            <ArrowBackIcon onClick={backs} />
          </div>
          <p className="txtdunkel texteins">
            Schreiben Sie eine Nachricht an {cname}
          </p>
          <div className="textcenter">
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            <span
              className="abstandlinks pointer texteins fweight"
              onClick={() => sendb(name)}
            >
              Senden
            </span>
          </div>
        </div>{" "}
        <CFenster
          jan={janan}
          nach={nachrichten}
          janhob={janhob}
          janalt={janalt}
        />
      </div>
    </Fragment>
  );
}
