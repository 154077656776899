import React, { useEffect, Fragment, useContext } from "react";
import { useNavigate } from "react-router-dom";
import firebase from "../firebase";
import AppContext from "../provider/AppContext";
import Datum from "./Datum";
import Geschlecht from "./Geschlecht";
import Hobbies from "./Hobbies";
import Chipi from "./Chipi";

import ProName from "./ProName";

export default function Logged() {
  const {
    pathy,
    inhalt,
    setInhalt,
    proindex,
    setProindex,
    setKeyarr,
    loading,
    setLoading,
  } = useContext(AppContext);

  const ref = firebase.firestore().collection(pathy);

  useEffect(() => {
    if (pathy === "ping") {
    } else {
      let zwishcnena = [];
      const unsubscribe = ref.onSnapshot((snapshot) => {
        if (snapshot.size) {
          let myDataArray = [];
          snapshot.forEach((doc) => {
            myDataArray.push({ ...doc.data() });
          });
          var k;
          setInhalt(myDataArray);
          for (
            k = 0;
            myDataArray !== "undefined" && k < myDataArray.length;
            k++
          ) {
            if (Object.keys(myDataArray[k]).includes("name")) {
              zwishcnena.push(myDataArray[k].name);
            } else {
              setProindex(k);
            }
          }
          setKeyarr(zwishcnena);

          setLoading(false);
        } else {
          console.log("imElseblock");
        }
      });

      return () => {
        unsubscribe();
      };
    }
  }, []);

  const navigate = useNavigate();
  function weiterD() {
    navigate("/chat");
  }

  return (
    <Fragment>
      {loading ? (
        <p>Lädt..</p>
      ) : proindex === 111 ? (
        <p>Lädt..</p>
      ) : inhalt.length === 1 &&
        proindex > 0 &&
        proindex !== 111 &&
        inhalt[proindex].code === "undefined" ? (
        <p>Lädt..</p>
      ) : inhalt === "undefined" ? (
        <p>Lädt..</p>
      ) : inhalt[proindex] === "undefined" ? (
        <p>Lädt..</p>
      ) : (
        <div className="txtgelb">
          <h3 className="centering margin-top margin-rund">
            Zugangscode: {inhalt[proindex].code}
          </h3>

          {inhalt[proindex].weiter ? (
            <button onClick={weiterD}>Weiter</button>
          ) : inhalt[proindex].profile === "none" ? (
            <ProName pathy={pathy} />
          ) : inhalt[proindex].age === 0 ? (
            <Datum pathy={pathy} />
          ) : inhalt[proindex].gender === "none" ? (
            <Geschlecht pathy={pathy} />
          ) : inhalt[proindex].hobbies.length < 7 ? (
            <div>
              {" "}
              <Chipi />
              <Hobbies pathy={pathy} />
            </div>
          ) : (
            <p>Es ist ein Fehler unterlaufen</p>
          )}
        </div>
      )}
    </Fragment>
  );
}
