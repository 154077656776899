// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
import firebase from "firebase/compat/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import "firebase/compat/firestore";
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCEHjsHqdDaXqK4oTB2t5gsKliHLPzPH_Q",
  authDomain: "liebesingles.firebaseapp.com",
  projectId: "liebesingles",
  storageBucket: "liebesingles.appspot.com",
  messagingSenderId: "136041665618",
  appId: "1:136041665618:web:f89cdfbb95c2278e2bfb8b",
};

// Initialize Firebase
// const app = initializeApp(firebaseConfig);

firebase.initializeApp(firebaseConfig);

export default firebase;
