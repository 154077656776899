import React, { Fragment, useContext } from "react";

import firebase from "../firebase";
import AppContext from "../provider/AppContext";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

export default function Chipi() {
  const { inhalt, proindex } = useContext(AppContext);

  const ref = firebase.firestore();

  let ausgewaehlt = [];
  let ausjewahlt = [];

  function clickz(m, i) {
    ausgewaehlt = inhalt[proindex].hobslist;
    ausjewahlt = inhalt[proindex].hobbies;

    if (i > 0) {
      ausjewahlt.splice(i, 1);
    }

    if (i > 0) {
      ausgewaehlt.push(m);
    }

    ref
      .collection(inhalt[proindex].code)
      .doc("profil")
      .update({ hobslist: ausgewaehlt, hobbies: ausjewahlt })
      .then((ausgewaehlt = []))
      .then((ausjewahlt = []))
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <Fragment>
      <div>
        {inhalt[proindex].hobbies.map((item, index) => (
          <div key={item} className="abstandmini">
            <Stack direction="row" spacing={1}>
              <Chip label={item} onDelete={() => clickz(item, index)} />
            </Stack>
          </div>
        ))}
      </div>
    </Fragment>
  );
}
