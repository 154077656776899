import React, { Fragment, useState } from "react";

import firebase from "../firebase";

export default function Datum(props) {
  const ref = firebase.firestore();
  const [num, setNum] = useState();
  const [valida, setValida] = useState("");

  function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  function getlength(number) {
    return number.toString().length;
  }

  function addSchool(newSchool) {
    if (num !== "" && isNumeric(num) && getlength(num) === 2 && num > 17) {
      ref
        .collection(props.pathy)
        .doc("profil")
        .update(newSchool)
        .catch((err) => {
          console.error(err);
        });
      setValida("");
    } else {
      console.log(num !== "", isNumeric(num), getlength(num) === 2);
      setValida(
        "Geben Sie Ihr Alter als 2-Stellige Zahl zwischen 18 und 99 an. Achte darauf, dass du kein Leerzeichen eingibst."
      );
    }
  }

  return (
    <Fragment>
      <div className="centering">
        <p>Geben Sie Ihr Alter an</p>
        <input
          type="number"
          min="18"
          max="99"
          value={num}
          onChange={(e) => setNum(e.target.value)}
        />
        <button onClick={() => addSchool({ age: num })}>Weiter</button>
        <p className="txtgelb texteins">{valida}</p>
      </div>{" "}
    </Fragment>
  );
}
