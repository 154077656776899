import React, { Fragment } from "react";

import firebase from "../firebase";

export default function Geschlecht(props) {
  const ref = firebase.firestore();

  function addSchool(newSchool) {
    ref
      .collection(props.pathy)
      .doc("profil")
      .update(newSchool)
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <Fragment>
      <div className="centering">
        <p>Geben Sie Ihr Geschlecht an</p>
        <button
          onClick={() => addSchool({ gender: "male" })}
          className="abstrechts"
        >
          Mann
        </button>
        <button
          onClick={() => addSchool({ gender: "female" })}
          className="abstrechts"
        >
          Frau
        </button>
        <button onClick={() => addSchool({ gender: "other" })}>Andere</button>
      </div>{" "}
    </Fragment>
  );
}
