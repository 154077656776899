import React, { Fragment } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

export default function Loesung() {
  const navigate = useNavigate();
  function bars() {
    navigate("/");
  }

  const chateins = [
    "Hi! wir haben viele Hobbies gemeinsam. Du musst abenteuerlustig, aber auch ein bisschen verrückt sein. :)",
    "Oh vielen Dank! Wir haben so viele Interessen gemeinsam!",
    "Ja, und natürlich mache ich auch andere Sachen gerne. Reisen zum Beispiel. Machst Du das auch gerne?",
    "Ich mag Kurztrips. Meine beste Freundin war im Oktober in Aachen. Da möchte ich auch mal hin",
    "Das ist cool. Ja Aachen soll eine schöne Stadt sein.",
    "Ich muss gehen. Lass uns auf jeden Fall wann anders mal weiter schreiben!",
    "Ah okay...ja bis bald mal!",
  ];

  const jan = [
    "Oh vielen Dank! Wir haben so viele Interessen gemeinsam!",
    "Ich mag Kurztrips. Meine beste Freundin war im Oktober in Aachen. Da möchte ich auch mal hin",
    "Ich muss gehen. Lass uns auf jeden Fall wann anders mal weiter schreiben!",
  ];

  return (
    <Fragment>
      <div className="centering">
        <div className="margin-top margin-rund">
          <div className="pointer">
            <ArrowBackIcon onClick={bars} />
          </div>

          <p>
            Lösung: Im Chat verrät Euch Jana, dass ihre beste Freundin (also
            Katrin) einen Kurztripp nach{" "}
            <span className="txtgelb fweightgross">Aachen</span> gemacht hat.
          </p>

          <div className="texteins">
            <div>
              {chateins.map((item, index) => (
                <div
                  key={item}
                  className={jan.includes(item) ? "chatich" : "chatanders"}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
