import React, { useState } from "react";
import AppContext from "./AppContext";

const LoveContext = ({ children }) => {
  const [cname, setCname] = useState("blank");
  const [pathy, setPathy] = useState("ping");
  const [laden, setLaden] = useState(true);
  const [loading, setLoading] = useState(false);
  const [proindex, setProindex] = useState(111);
  const [keyarr, setKeyarr] = useState([]);
  const [inhalt, setInhalt] = useState([
    {
      code: "none",
      age: 0,
      gender: "none",
      hobbies: ["Hobbies"],
      paula: ["Rando"],
    },
  ]);
  const [chathlt, setChathlt] = useState([
    {
      name: "none",
      chat: ["blank"],
    },
  ]);
  const [swiv, setSwiv] = useState({});
  const [nkeya, setNkeya] = useState([]);

  const context = {
    pathy,
    setPathy,
    inhalt,
    setInhalt,
    cname,
    setCname,
    chathlt,
    setChathlt,
    laden,
    setLaden,
    proindex,
    setProindex,
    keyarr,
    setKeyarr,
    loading,
    setLoading,
    swiv,
    setSwiv,
    nkeya,
    setNkeya,
  };
  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};
export default LoveContext;
