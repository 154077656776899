import React, { Fragment, useContext } from "react";

import AppContext from "../provider/AppContext";

export default function CFenster(props) {
  const { chathlt, laden, cname } = useContext(AppContext);

  const nachrichten = [
    "Hi, mir ist gerade ein bisschen langweilig. Wie geht's Dir?",
    "Hey, das ist nett. Wie gehts?",
    "yo yo yo...was geht ab?",
    "hey :)",
    "Moin Moin, du hast ja unterschiedliche Hobbys. Wow.",

    "Ich finde Dein Profil mega interessant",
    "Wir sind von den Interessen her schon ziemlich verschieden. Aber Gegensätze ziehen sich an",
    "Du wirkst echt spannend...",
    "Faszinierend.",

    "... ich bin mir nicht sicher, ob ich das richtig verstehe",
    "Ich bin mir nicht sicher, wie ich das interpretieren soll.",
    "Kannst Du das präzisieren?",
    "Ich weiß nicht so recht...",

    "Kletterst Du lieber in der Natur oder an künstlichen Kletterwänden?",
    "Bist Du gerne viel an der frischen Luft?",
    "Interessierst Du Dich auch für Geschichte?",
    "Sprichst Du noch andere Sprachen außer Deutsch? Je parle francais...",

    "Das finde ich ziemlich interessant",
    "Das ist echt cool",
    "Ich finde das ziemlich schräg...aber sympatisch.",
    "Haha... da muss ich jetzt lachen.",

    "Ich muss leider los. Wir sollten uns aber unbedingt später weiter unterhalten!",
  ];

  const janan = [
    "Hallo, du bist ja originell ^^ ...ich mag Dein Profil. Wir haben viele gemeinsame Interessen",

    "Hä? ... das verstehe ich nicht..",
    "Verreist Du gerne?",

    "Ich mag Kurztrips. Meine beste Freundin war im Oktober in Aachen. Da möchte ich auch mal hin",

    "Ich muss gehen. Lass uns auf jeden Fall wann anders mal weiter schreiben!",
  ];

  // const displayThis = cname === "blank" ? (
  //   <>
  // ) : (
  //   <div>
  //     <Einloggen />
  //     <Code />

  //     <Registrieren />
  //   </div>
  // );

  return (
    <Fragment>
      {laden ? (
        <p>Loading...</p>
      ) : typeof chathlt === "undefined" ? (
        <p>Schreiben Sie eine Nachricht</p>
      ) : (
        <div className="margin-top margin-rund">
          {chathlt.chat.map((item, index) => (
            <div key={item}>
              <p
                className={
                  nachrichten.includes(item) ||
                  janan.includes(item) ||
                  props.janhob.includes(item) ||
                  props.janalt.includes(item) ||
                  item === `${cname} hat den Chat verlassen`
                    ? "chatich"
                    : "chatanders"
                }
              >
                {item}
              </p>
            </div>
          ))}
        </div>
      )}
    </Fragment>
  );
}
