import React, { Fragment, useState, useContext } from "react";

import firebase from "../firebase";
import AppContext from "../provider/AppContext";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

export default function ProfileF() {
  const { inhalt, setCname, proindex, nkeya, setNkeya } =
    useContext(AppContext);
  const ref = firebase.firestore();
  // const navigate = useNavigate();
  const [auf, setAuf] = useState(false);

  let matchwomen = [
    [
      "Lina",
      "Hobbies: Spazieren, Karate +3 weitere",
      "Anzeige nur bei Männern",
    ],
    ["Love", "Hobbies: Backen, Gärtnern +3 weitere", "Anzeige nur bei Männern"],
    ["Sina", "Hobbies: Tauchen, Wandern +3 weitere", "Anzeige nur bei Männern"],
    [
      "Gerda",
      "Hobbies: DeutschRap, Lesen +3 weitere",
      "Anzeige nur bei Männern",
    ],
    ["Celine", "Hobbies: Malen, Töpfern +3 weitere", "Anzeige nur bei Männern"],
    ["Ummi", "Hobbies: Reisen, Kochen +3 weitere", "Anzeige nur bei Männern"],
    [
      "Claudia",
      "Hobbies: Autos, Skifahren +3 weitere",
      "Anzeige nur bei Männern",
    ],
    [
      "Svenja",
      "Hobbies: Aktien, Immobilien +3 weitere",
      "Anzeige nur bei Männern",
    ],
    ["Rita", "Hobbies: Reiten, Wandern +3 weitere", "Anzeige nur bei Männern"],
    [
      "Naomi",
      "Hobbies: Filme, Kriminalromane +3 weitere",
      "Anzeige nur bei Männern",
    ],
    ["Roxi", "Hobbies: Tauchen, Fischen +3 weitere", "Anzeige nur bei Männern"],
    [
      "Francoise",
      "Hobbies: Kaligraphie, Geschichte +3 weitere",
      "Anzeige nur bei Männern",
    ],
    [
      "Jana",
      "Hobbies: Klettern, DeutschRap +3 weitere",
      "Anzeige nur bei Männern",
    ],
    [
      "Manuela",
      "Hobbies: Reiten, Wandern +3 weitere",
      "Anzeige nur bei Männern",
    ],
    [
      "Paula",
      "Hobbies: Stricken, Kriminalromane +3 weitere",
      "Anzeige nur bei Männern",
    ],
  ];

  function aufzu() {
    setAuf(!auf);
  }

  function chatclick(nami) {
    if (!nkeya.includes(nami)) {
      setNkeya((oldArray) => [...oldArray, nami]);
      console.log("chatexi nichtt");
    }

    ref
      .collection(inhalt[proindex].code)
      .doc(nami)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setCname(nami);
          ref
            .collection(inhalt[proindex].code)
            .doc("profil")
            .update({
              active: nami,
            })
            .catch((err) => {
              console.error(err);
            });
          console.log("Document data existingchat:");
        } else {
          setCname(nami);
          // doc.data() will be undefined in this case
          console.log("No such documenut!");
          ref
            .collection(inhalt[proindex].code)
            .doc("profil")
            .update({
              active: nami,
            })
            .then(
              ref
                .collection(inhalt[proindex].code)
                .doc(nami)
                .set({ name: nami, chat: [""] })
                .catch((err) => {
                  console.error(err);
                })
            );
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  }

  return (
    <Fragment>
      <div>
        {auf ? (
          <div>
            <p onClick={aufzu} className="txtgelb fweightgross pointer">
              <RemoveIcon fontSize="inherit" />
              <span> Profile von Frauen</span>
            </p>
            <div className="txtleft txtdunkel">
              {matchwomen.map((item, index) => (
                <div key={item[0]}>
                  <table>
                    <tr>
                      <td className="fweightgross">{item[0]}</td>
                      <td>
                        {" "}
                        <button
                          onClick={() => chatclick(item[0])}
                          className="txtright"
                        >
                          Chatten
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>{item[1]}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Alter: {item[2]}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td className="txtlila">
                        Interessiert? unterhalten dich mit dieser Person
                      </td>
                      <td></td>
                    </tr>
                  </table>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div>
            <p onClick={aufzu} className="txtgelb fweightgross pointer">
              <AddIcon fontSize="inherit" />
              <span> Profile von Frauen</span>
            </p>
          </div>
        )}
      </div>
    </Fragment>
  );
}
