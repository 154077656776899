import React, { Fragment } from "react";

import HKachel from "./HKachel";

export default function Hobbies() {
  return (
    <Fragment>
      <div className="centering">
        <div className="margin-top margin-rund">
          <p>Wählen Sie 5 Hobbies:</p>
        </div>
        <HKachel />
      </div>{" "}
    </Fragment>
  );
}
