import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoveContext from "./provider/LoveContext";
import "./App.css";
import Main from "./pages/main";
import Impressum from "./pages/impressum";
import Datenschutz from "./pages/datenschutz";
import Profil from "./pages/profil";
import Chat from "./pages/chat";
import Loschs from "./pages/loschs";
import Loesung from "./pages/loesung";

function App() {
  return (
    <>
      <LoveContext>
        <Router>
          <Routes>
            <Route exact path="/" element={<Main />} />
            <Route exact path="/impressum" element={<Impressum />} />
            <Route exact path="/datenschutz" element={<Datenschutz />} />
            <Route exact path="/profil" element={<Profil />} />
            <Route exact path="/loeschen" element={<Loschs />} />
            <Route exact path="/loesung" element={<Loesung />} />
            <Route exact path="/chat" element={<Chat />} />
          </Routes>
        </Router>
      </LoveContext>
    </>
  );
}

export default App;
