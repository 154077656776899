import React, { Fragment } from "react";
import Abo from "./Abo";
import { useNavigate } from "react-router-dom";

export default function Start() {
  const navigate = useNavigate();
  function handleClick() {
    navigate("/impressum");
  }

  function clickDS() {
    navigate("/datenschutz");
  }

  return (
    <Fragment>
      <div className="centering">
        <div className="margin-top margin-rund">
          <h3 className="txtgelb">Willkommen bei Liebe|Singles.</h3>
          <p className="txtgelb">
            Die Seite für die <span>inneren Werte.</span>
          </p>
          <Abo />
        </div>{" "}
        <div className="abstanduklein"></div>
        <p className="textcenter footer">
          <span
            className="textcenter pointer margin-agb texteins"
            onClick={handleClick}
          >
            Impressum
          </span>{" "}
          <span
            className="textcenter pointer margin-agb texteins"
            onClick={clickDS}
          >
            Datenschutz
          </span>
        </p>{" "}
      </div>
    </Fragment>
  );
}
