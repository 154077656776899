import React, { Fragment, useState, useContext } from "react";

import firebase from "../firebase";
import AppContext from "../provider/AppContext";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

export default function ProfileM() {
  const { inhalt, setCname, proindex, nkeya, setNkeya } =
    useContext(AppContext);
  const ref = firebase.firestore();

  const [auf, setAuf] = useState(false);

  let matchwomen = [
    ["Maik", "Hobbies: Jagen, Reisen +3 weitere", "37"],
    ["Lorenzo", "Hobbies: Judo, Tennis +3 weitere", "49"],
    ["Peter", "Hobbies: Sudoku, Schach +3 weitere", "34"],
    ["Milo", "Hobbies: Gaming, Fußball +3 weitere", "18"],
    ["Ingo", "Hobbies: Kochen, Reisen +3 weitere", "24"],
    ["Micha", "Hobbies: Wandern, Segelfliegen +3 weitere", "31"],
    ["James", "Hobbies: Kreuzfahrten, Politik +3 weitere", "30"],
    ["Piotr", "Hobbies: Skifahren, Surfen +3 weitere", "27"],
    ["Siggi", "Hobbies: Kart-Fahren, Botanik +3 weitere", "53"],
    ["Zippl", "Hobbies: Nordic Walking, Reisen +3 weitere", "64"],
    ["Fil", "Hobbies: Fischen, Jagen +3 weitere", "83"],
    ["Qwww", "Hobbies: Clubbing, Esoterik +3 weitere", "33"],
    ["Torben", "Hobbies: Kampfsport, Fischen +3 weitere", "41"],
    ["Jannik", "Hobbies: Fußball, Skifahren +3 weitere", "19"],
  ];

  function aufzu() {
    setAuf(!auf);
  }

  function chatclick(nami) {
    if (!nkeya.includes(nami)) {
      setNkeya((oldArray) => [...oldArray, nami]);
    }

    ref
      .collection(inhalt[proindex].code)
      .doc(nami)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setCname(nami);
          ref
            .collection(inhalt[proindex].code)
            .doc("profil")
            .update({
              active: nami,
            })
            .catch((err) => {
              console.error(err);
            });
          console.log("Document data existingchat:");
        } else {
          setCname(nami);
          // doc.data() will be undefined in this case
          console.log("No such documenut!");
          ref
            .collection(inhalt[proindex].code)
            .doc("profil")
            .update({
              active: nami,
            })
            .then(
              ref
                .collection(inhalt[proindex].code)
                .doc(nami)
                .set({ name: nami, chat: [""] })
                .catch((err) => {
                  console.error(err);
                })
            );
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  }

  return (
    <Fragment>
      <div>
        {auf ? (
          <div>
            <p onClick={aufzu} className="txtgelb fweightgross pointer">
              <RemoveIcon fontSize="inherit" />
              <span> Profile von Männern</span>
            </p>
            <div className="txtleft txtdunkel">
              {matchwomen.map((item, index) => (
                <div key={item[0]}>
                  <table>
                    <tr>
                      <td className="fweightgross">{item[0]}</td>
                      <td>
                        {" "}
                        <button
                          onClick={() => chatclick(item[0])}
                          className="txtright"
                        >
                          Chatten
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>{item[1]}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Alter: {item[2]}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td className="txtlila">
                        Interessiert? unterhalten dich mit dieser Person
                      </td>
                      <td></td>
                    </tr>
                  </table>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <p onClick={aufzu} className="txtgelb fweightgross pointer">
            <AddIcon fontSize="inherit" />
            <span> Profile von Männern</span>
          </p>
        )}
      </div>
    </Fragment>
  );
}
