import React, { Fragment, useContext } from "react";

import firebase from "../firebase";
import AppContext from "../provider/AppContext";
import WeiterB from "./WeiterB";

export default function HKachel() {
  const { inhalt, proindex } = useContext(AppContext);
  const ref = firebase.firestore();
  let ausgewaehlt = [];
  let ausjewahlt = [];

  function clickd(tem, ii) {
    ausgewaehlt = inhalt[proindex].hobslist;
    ausjewahlt = inhalt[proindex].hobbies;

    if (ii > 0 && inhalt[proindex].hobbies.length < 6) {
      ausgewaehlt.splice(ii, 1);
    }

    if (ii > 0 && inhalt[proindex].hobbies.length < 6) {
      ausjewahlt.push(tem);
    }

    ref
      .collection(inhalt[proindex].code)
      .doc("profil")
      .update({ hobslist: ausgewaehlt, hobbies: ausjewahlt })
      .then((ausgewaehlt = []))
      .then((ausjewahlt = []))
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <Fragment>
      {inhalt[proindex].hobslist.map((item, index) => (
        <div key={item}>
          <div className="hobbydiv pointer" onClick={() => clickd(item, index)}>
            <p>{item}</p>
          </div>
        </div>
      ))}
      <WeiterB />
    </Fragment>
  );
}
