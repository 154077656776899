import React, { useState, Fragment, useContext } from "react";

import firebase from "../firebase";
import AppContext from "../provider/AppContext";
import Logged from "../components/Logged";
import { useNavigate } from "react-router-dom";

export default function Profil() {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [valida, setValida] = useState("");

  const ref = firebase.firestore();

  const { pathy, setPathy } = useContext(AppContext);

  function handleClick() {
    navigate("/impressum");
  }

  function clickDS() {
    navigate("/datenschutz");
  }

  function addSchool(newSchool) {
    if (
      newSchool.includes("uq9t") ||
      newSchool.includes("UQ9T") ||
      newSchool.includes("ww")
    ) {
      ref
        .collection(newSchool)
        .limit(1)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            ref
              .collection(newSchool)
              .doc("profil")
              .set({
                profile: "none",
                code: newSchool,
                age: 0,
                gender: "none",
                hobbies: ["Hobbies"],
                weiter: false,
                active: "none",
                hobslist: [
                  "Hobbies",
                  "Turnen",
                  "Tauchen",
                  "Tischtennis",
                  "TV-Serien",
                  "Tennis",
                  "Klettern",
                  "Schwimmen",
                  "Reiten",
                  "Wandern",
                  "Kart-Fahren",
                  "Architektur",
                  "Lesen",
                  "DeutschRap",
                  "Reggae",
                  "Jazz",
                  "Gaming/Computer Spiele",
                  "Gartenarbeit",
                  "Reisen",
                ],
                matching: "none",
                chat: { nuu: ["some"] },
              })
              .catch((err) => {
                console.error(err);
              })
              .then(setPathy(newSchool));
          } else {
            setPathy(newSchool);
          }
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
      setValida("");
    } else {
      setValida(
        "Upps, etwas ist schiefgelaufen. Ist die Schreibweise korrekt? Achte darauf den Code ohne Leerzeichen einzugeben."
      );
    }
  }

  return (
    <Fragment>
      <div className="centering">
        <div className="margin-top margin-rund">
          {pathy === "ping" ? (
            <div className="txtgelb">
              <h3>Sie haben bereits ein kostenpflichtiges Abo?</h3>

              <p>Bitte geben Sie Ihren Zugangsberechtigungs-Code ein</p>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />

              <button onClick={() => addSchool(title)}>Zum Profil</button>
              <p className="txtgelb texteins">{valida}</p>
            </div>
          ) : (
            <Logged />
          )}
        </div>{" "}
        <div className="abstandu"></div>
        <p className="textcenter footer centering">
          <span
            className="impress textcenter pointer margin-agb texteins"
            onClick={handleClick}
          >
            Impressum
          </span>{" "}
          <span
            className="impress textcenter pointer margin-agb texteins"
            onClick={clickDS}
          >
            Datenschutz
          </span>
        </p>{" "}
      </div>
    </Fragment>
  );
}
