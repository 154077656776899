import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import womantabl from "../womantabl.png";

import Philo from "./Philo";

export default function Abo() {
  const navigate = useNavigate();
  function clickCode() {
    navigate("/profil");
  }

  function ale() {
    alert(
      "Aufgrund der großen Nachfrage ist die Zahlungsfunktion gerade überlastet. - Versuche es später noch einmal."
    );
  }

  return (
    <Fragment>
      <div className="txtrosa">
        <h3>
          Erstelle ein Profil, um Deinen zukünftigen Traumpartner oder
          Traumpartnerin zu kontaktieren.
        </h3>
        <Philo />
        <div className="textcenter">
          <img
            className="start-img"
            src={womantabl}
            alt="Frau mit Tablet-Computer"
          />
        </div>{" "}
        <div className="textcenter">
          <p>
            Schließe dazu unser 18-Monatsabonnement für nur 23,90 Euro/Monat ab!
          </p>
          <button onClick={ale}>Abonnement abschließen</button>
          <p className="txtgelb">
            Ich habe bereits ein kostenpflichtiges Abo:{" "}
            <span
              className="textcenter pointer fweightgross"
              onClick={clickCode}
            >
              Code eingeben.
            </span>
          </p>{" "}
        </div>
      </div>
    </Fragment>
  );
}
