import React, { Fragment, useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import firebase from "../firebase";
import AppContext from "../provider/AppContext";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import ProfileM from "../components/ProfileM";
import ProfileF from "../components/ProfileF";
import PersonF from "../components/PersonF";

export default function Chat() {
  const navigate = useNavigate();
  const [match, setMatch] = useState(true);
  const { inhalt, proindex, pathy, swiv, setSwiv, nkeya, cname } =
    useContext(AppContext);

  const ref = firebase.firestore();

  useEffect(() => {
    const unsubscribe = ref
      .collection(pathy)
      .doc("profil")
      .onSnapshot(function (doc) {
        setSwiv(doc.data());
        console.log(doc.data().active, "doc.data().active");
      });

    return () => {
      unsubscribe();
    };
  }, []);

  function bars() {
    setMatch(true);
    console.log(nkeya);
  }

  function zulo() {
    navigate("/loeschen");
  }

  return (
    <Fragment>
      {match ? (
        <div className="centering">
          <div className="margin-top margin-rund">
            <p className="bisschen fweightgross txtgelb">Dein Profil:</p>
            <table>
              <tr>
                <td className="fweightgross">Alter:</td>
                <td>{inhalt[proindex].age}</td>
              </tr>
              <tr>
                <td className="fweightgross">Hobbies:</td>
                <td>5 ausgewählte Hobbies</td>
              </tr>
            </table>
          </div>

          <div className="centering">
            <div className="margin-top margin-rund txtgelb" onClick={zulo}>
              <div className="pointer">
                <DeleteIcon fontSize="inherit" />
                <span> Profil löschen</span>{" "}
              </div>{" "}
            </div>
            <div className="margin-top margin-rund">
              <div className="abstanduklein"></div>
              <button onClick={() => setMatch(false)}>
                Andere Profile ansehen
              </button>
            </div>
          </div>
        </div>
      ) : swiv.active === "none" || cname === "blank" ? (
        <div>
          <div className="centering textcenter">
            <div className="margin-top margin-rund">
              <div className="pointer">
                <ArrowBackIcon onClick={bars} />
              </div>{" "}
              <p>
                Starte einen Chat mit jemandem, dessen Profil Du ansprechend
                findest.
              </p>
              <p>Mitglieder die gerade Online sind:</p>
              <ProfileM />
              <ProfileF />
            </div>{" "}
          </div>{" "}
        </div>
      ) : (
        <PersonF />
      )}
    </Fragment>
  );
}
