import React, { Fragment, useContext } from "react";
import { useNavigate } from "react-router-dom";
import firebase from "../firebase";
import AppContext from "../provider/AppContext";

export default function Loschs() {
  const navigate = useNavigate();
  const { inhalt, proindex, setProindex, setInhalt, pathy, setLoading, nkeya } =
    useContext(AppContext);

  const ref = firebase.firestore();

  function losch() {
    var result = "";
    var characters = "abcdefghijkmnpqrstuvwxyz123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 3; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    ref
      .collection("del")
      .doc(result + inhalt[proindex].code)
      .set({ [inhalt[proindex].code]: inhalt })
      .then(() => {
        ref
          .collection(pathy)
          .doc("profil")
          .set({
            profile: "none",
            code: pathy,
            active: "none",
            age: 0,
            gender: "none",
            hobbies: ["Hobbies"],
            weiter: false,
            hobslist: [
              "Hobbies",
              "Turnen",
              "Tauchen",
              "Tischtennis",
              "TV-Serien",
              "Tennis",
              "Klettern",
              "Schwimmen",
              "Reiten",
              "Wandern",
              "Kart-Fahren",
              "Architektur",
              "Lesen",
              "DeutschRap",
              "Reggae",
              "Jazz",
              "Gaming/Computer Spiele",
              "Gartenarbeit",
              "Reisen",
            ],
            matching: "none",
            chat: { nuu: ["some"] },
          })
          .then(() => {
            setLoading(true);

            setProindex(111);
            setInhalt([
              {
                profile: "none",
                code: pathy,
                age: 0,
                active: "none",
                gender: "none",
                hobbies: ["Hobbies"],
                weiter: false,
                hobslist: [
                  "Hobbies",
                  "Turnen",
                  "Tauchen",
                  "Tischtennis",
                  "TV-Serien",
                  "Tennis",
                  "Klettern",
                  "Schwimmen",
                  "Reiten",
                  "Wandern",
                  "Kart-Fahren",
                  "Architektur",
                  "Lesen",
                  "DeutschRap",
                  "Reggae",
                  "Jazz",
                  "Gaming/Computer Spiele",
                  "Gartenarbeit",
                  "Reisen",
                ],
                matching: "none",
                chat: { nuu: ["some"] },
              },
            ]);

            nkeya.forEach((element) =>
              ref
                .collection(pathy)
                .doc(element)
                .delete()
                .then(() => {
                  console.log("Document successfully deleted!");
                })
                .catch((error) => {
                  console.error("Error removing document: ", error);
                })
            );
            navigate("/profil");
          })
          .catch((err) => {
            console.error(err);
          });
      });
  }

  return (
    <Fragment>
      <div className="margin-top margin-rund">
        <div className="centering">
          <p className="texteins">
            Bist Du sicher, dass Du Dein Profil löschen willst? Du kannst diesen
            Schritt nicht rückgängig machen. Deine eingegebenen Daten gehen
            verloren, Du kannst aber jederzeit ein neues Profil anlegen.
          </p>

          <p className="pointer hobbydiv txtgelb" onClick={losch}>
            Profil löschen
          </p>
        </div>{" "}
      </div>
    </Fragment>
  );
}
