import React, { useState, Fragment } from "react";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

export default function Philo() {
  const [auf, setAuf] = useState(false);

  return (
    <Fragment>
      {auf ? (
        <div>
          <div className="pointer" onClick={() => setAuf(!auf)}>
            <RemoveIcon fontSize="inherit" /> <span> Unsere Philosophie</span>
          </div>

          <p className="texteins">
            Wir glauben an die Liebe. Und wir wissen, dass bei der Suche nach
            Deiner Traumpartnerin oder Deinem Traumpartner die inneren Werte
            entscheidend sind.
          </p>
          <p className="texteins">
            Darum gibt es bei Liebe|Singles keine Profilbilder. - Entscheidend
            sind die gleichen Interessen - und dass Ihr mit denen die zu Euch
            passen, schnell und unkomliziert ins Gespräch kommt.{" "}
          </p>
        </div>
      ) : (
        <div className="pointer" onClick={() => setAuf(!auf)}>
          <AddIcon fontSize="inherit" /> <span> Unsere Philosophie</span>
        </div>
      )}
    </Fragment>
  );
}
