import React, { Fragment, useState } from "react";

import firebase from "../firebase";

export default function ProName(props) {
  const ref = firebase.firestore();
  const [name, setName] = useState("");
  const [valida, setValida] = useState("");

  function addSchool(newSchool) {
    if (name !== "") {
      ref
        .collection(props.pathy)
        .doc("profil")
        .update(newSchool)
        .catch((err) => {
          console.error(err);
        });
      setValida("");
    } else {
      setValida("Ihr Profilname muss mindestens ein Zeichen enthalten");
    }
  }

  return (
    <Fragment>
      <div className="centering">
        <p>Wählen Sie einen Profilnamen</p>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <button onClick={() => addSchool({ profile: name })}>Weiter</button>
        <p className="txtgelb texteins">{valida}</p>
      </div>{" "}
    </Fragment>
  );
}
